@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap);
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavBar_NavBar__2htgy {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.NavBar_NavBar__2htgy .NavBar_logo__1FfQw {
  margin-top: 0.3rem;
  margin-left: 0.8rem;
  font-size: 20px;
  font-weight: 500;
  align-self: center;
  text-decoration: none;
  color: black;
}

.NavBar_NavBar__2htgy .NavBar_items__3MZtz {
  display: flex;
}

.NavBar_NavBar__2htgy .NavBar_item__30QyX {
  margin-right: 1rem;
  font-size: 18px;
  align-self: center;
  text-decoration: none;
  color: black;
  transition: color 0.2s ease-in-out;
}

.NavBar_NavBar__2htgy .NavBar_button__1MkMD {
  margin-right: 1rem;
  align-self: center;
}

.NavBar_NavBar__2htgy .NavBar_item__30QyX:hover {
  color: #03a0b5;
}

.NavBar_NavBar__2htgy .NavBar_activeItem__3QJRh {
  margin-right: 1rem;
  font-size: 18px;
  align-self: center;
  text-decoration: none;
  color: #03a0b5;
  transition: color 0.2s ease-in-out;
}

.Button_Button__1PGXx {
  display: inline-block;
  font-size: 15px;
  padding: 6px;
  background: #03a0b5;
  border: 2px solid #03a0b5;
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Button_Button__1PGXx:not(:last-child) {
  margin-right: 0.5rem;
}

.Button_Button__1PGXx:hover {
  background: rgb(39, 194, 214);
  border: 2px solid rgb(39, 194, 214);
}

.Button_inverted__1sLQ6 {
  display: inline-block;
  font-size: 15px;
  border-radius: 10px;
  padding: 6px;
  color: #03a0b5;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Button_inverted__1sLQ6:hover {
  border-radius: 9px;
  background: rgb(235, 247, 249);
}

.Button_inverted__1sLQ6:not(:last-child) {
  margin-right: 0.5rem;
}

.Button_invertedDelete__2Vqmb {
  display: inline-block;
  font-size: 15px;
  color: red;
  padding: 6px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Button_invertedDelete__2Vqmb:hover {
  border-radius: 10px;
  background: #ffd0c2;
}

.Button_invertedDelete__2Vqmb:not(:last-child) {
  margin-right: 0.5rem;
}

.Button_delete__3_hUF {
  display: inline-block;
  font-size: 15px;
  background: #f63c41;
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Button_delete__3_hUF:hover {
  border-radius: 10px;
  background: #d31122;
  border-color: #d31122;
}

.Button_delete__3_hUF:not(:last-child) {
  margin-right: 0.5rem;
}

.SignUpModal_Modal__3d_fa {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SignUpModal_Modal__3d_fa .SignUpModal_card__Qi9pZ {
  padding: 1rem;
  width: 90%;
  max-width: 800px;
  background: #ffffff;
  border-radius: 10px;
}

.SignUpModal_Modal__3d_fa .SignUpModal_title__31oa- {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
}

.SignUpModal_Modal__3d_fa .SignUpModal_footer__e13pC {
  display: flex;
  flex-direction: column;
}

.SignUpModal_Modal__3d_fa .SignUpModal_buttons__2Aexx {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.SignUpModal_Modal__3d_fa .SignUpModal_kindArea__dFcym {
  align-items: center;
  display: flex;
}

.SignUpModal_Modal__3d_fa .SignUpModal_label__MZxqi {
  font-weight: 400;
  font-size: 18px;
  margin: -3px 20px 0 0;
}

.SignUpModal_Modal__3d_fa .SignUpModal_serviceLabel__2yQTy {
  font-weight: 400;
  font-size: 18px;
  margin: 10px 1.5rem 0 0;
}

.SignUpModal_Modal__3d_fa .SignUpModal_serviceArea__3Nanl {
  display: flex;
}

.SignUpModal_Modal__3d_fa .SignUpModal_langFieldIcons__1mVeQ {
  margin-top: 0.9rem;
}

.SignUpModal_Modal__3d_fa .SignUpModal_langFieldIcon__qrSWX:hover {
  -webkit-filter: brightness(150%);
          filter: brightness(150%);
}

.SignUpModal_Modal__3d_fa .SignUpModal_header__1ci4W {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LoginModal_Modal__1ZKQv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginModal_Modal__1ZKQv .LoginModal_card__ko04Q {
  padding: 1rem;
  width: 400px;
  background: #ffffff;
  border-radius: 10px;
}

.LoginModal_Modal__1ZKQv .LoginModal_title__2IDwR {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
}

.LoginModal_Modal__1ZKQv .LoginModal_footer__VD1l_ {
  display: flex;
  flex-direction: column;
}

.LoginModal_Modal__1ZKQv .LoginModal_buttons__3BzHS {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.LoginModal_Modal__1ZKQv .LoginModal_header__2nRNM {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LoginModal_Modal__1ZKQv .LoginModal_instruction__1zmRC {
  font-size: 14px;
}

.Footer {
  font-weight: 500;
  text-align: center;
  color: #027888;
  margin-bottom: 1.5rem;
  transition: color 0.2s ease-in-out;
}

.Footer:hover {
  color: rgb(39, 194, 214);
}

.HomePage_HomePage__1lBTF {
  padding: 10px 5%;
}

.HomePage_HomePage__1lBTF .HomePage_section__1Xg1z {
  margin: 1rem auto;
  max-width: 1000px;
}

.HomePage_HomePage__1lBTF .HomePage_infoSection__oCivg {
  margin: 1.5rem auto;
  max-width: 1200px;
}

.HomePage_HomePage__1lBTF .HomePage_previewSection__2TS7u {
  margin: 1.5rem auto;
  max-width: 1000px;
}

.HomePage_HomePage__1lBTF .HomePage_invertedSection__3wvnS {
  margin: 0 -6%;
  padding: 5% 5%;
  background: #03a0b5;
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.HomePage_HomePage__1lBTF .HomePage_invertedSection__3wvnS:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.HomePage_HomePage__1lBTF .HomePage_previewHeader__3u70I {
  text-align: center;
}

.HomePage_HomePage__1lBTF .HomePage_title__1-P16 {
  font-size: 28px;
  line-height: 30px;
  color: #027888;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-align: center;
}

.HomePage_HomePage__1lBTF .HomePage_title__1-P16:hover {
  color: rgb(39, 194, 214);
}

.HomePage_HomePage__1lBTF .HomePage_image__2feMx {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.HomePage_HomePage__1lBTF .HomePage_quote__CNXrk {
  font-size: 30px;
}

.HomePage_HomePage__1lBTF .HomePage_centeredQuote__CQiCy {
  font-size: 25px;
  text-align: center;
}

.HomePage_HomePage__1lBTF .HomePage_infoItem__1D8_W {
  display: block;
  margin-left: 10px;
}

.HomePage_HomePage__1lBTF .HomePage_previewArea__1R_tg {
  padding: 2rem 0;
  display: flex;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.HomePage_HomePage__1lBTF .HomePage_previewArea__1R_tg::-webkit-scrollbar {
  display: none;
}

.HomePage_HomePage__1lBTF .HomePage_previewCard__2bcLt {
  scroll-snap-align: center;
}

.HomePage_HomePage__1lBTF .HomePage_joinSection__3BrE0 {
  margin: 0 -6%;
  padding: 2rem 10%;
  background: rgb(235, 247, 249);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.HomePage_HomePage__1lBTF .HomePage_joinSection__3BrE0:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
}

.HomePage_HomePage__1lBTF .HomePage_icon__2DhvY {
  margin: 1rem 0.5rem 0;
}

.HomePage_HomePage__1lBTF .HomePage_paragraph__KpKRO {
  text-align: justify;
}

.SlantedCard_SlantedCard__3Kkh6 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.SlantedCard_SlantedCard__3Kkh6 .SlantedCard_shape__1hKb1 {
  overflow: hidden;
  background: #4787c9;
  max-width: 350px;
  width: 100%;
  height: 300px;
  border-radius: 30px;
  transform: skewX(-3deg) skewY(-3deg); /*Shorthand: skew(-5deg, -5deg); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.SlantedCard_SlantedCard__3Kkh6 .SlantedCard_shape__1hKb1:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.SlantedCard_SlantedCard__3Kkh6 .SlantedCard_customerPicture__1Tkik {
  height: 100%;
}

.SlantedCard_SlantedCard__3Kkh6 .SlantedCard_customerPicture__1Tkik img {
  height: 100%;
  transform: skewX(3deg) skewY(3deg) scale(1.22) translateX(20px)
    translateY(17px); /*Shorthand: skew(5deg, 5deg) scale(1.15);*/
}

.SlantedCard_SlantedCard__3Kkh6 .SlantedCard_shapeShadow__1hupF {
  position: absolute;
  margin: auto;
  background: #f0f7ff;
  max-width: 350px;
  width: 100%;
  height: 300px;
  border-radius: 30px;
  transform: skewX(-2deg) skewY(-2deg) rotate(-20deg); /*Shorthand: skew(-5deg, -5deg) rotate(-20deg);*/
  z-index: -10;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* reference: https://codesandbox.io/s/slanted-card-forked-wekn9?file=/index.html:311-1021 */

.PreviewCard_PreviewCard__34DjX {
  margin: 0 3rem;
}

.PreviewCard_PreviewCard__34DjX .PreviewCard_inner__3qMqh {
  display: flex;
  align-items: center;
  background: #ffffff;
  min-width: 400px;
  margin: -0.5rem;
}

.PreviewCard_PreviewCard__34DjX .PreviewCard_avatar__2axra {
  margin: 1rem 1.5rem 1rem 1rem;
}

.PreviewCard_PreviewCard__34DjX .PreviewCard_name__1kvQW {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
}

.PreviewCard_PreviewCard__34DjX .PreviewCard_languages__398Sl {
  font-size: 18px;
  line-height: 20px;
  color: #027888;
  margin-bottom: 0.5rem;
}

.PreviewCard_PreviewCard__34DjX .PreviewCard_location__1kdha,
.PreviewCard_email__1-01W {
  line-height: 20px;
}

.Paper_Paper__f5AzK,
.Paper_invertedPaper__14Q78,
.Paper_lightPaper__YhjaY {
  margin: 1rem 0;
  padding: 1rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Paper_invertedPaper__14Q78 {
  background: #03a0b5;
}

.Paper_invertedPaper__14Q78:hover {
  background: rgb(39, 194, 214);
}

.Paper_Paper__f5AzK:hover,
.Paper_invertedPaper__14Q78:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Paper_lightPaper__YhjaY:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16), 0 5px 15px rgba(0, 0, 0, 0.23);
}

.AboutUsPage_AboutUsPage__3wk2o {
  padding-top: 2rem;
  position: relative;
}

.AboutUsPage_AboutUsPage__3wk2o .AboutUsPage_avatarSection__2ORzO {
  margin: auto;
  max-width: 800px;
  padding-top: 1rem;
  margin-top: 0.5rem;
}

.AboutUsPage_AboutUsPage__3wk2o .AboutUsPage_title__tSKDA {
  font-size: 30px;
  line-height: 30px;
  color: #027888;
  text-align: center;
  margin-bottom: 1.5rem;
  transition: color 0.2s ease-in-out;
}

.AboutUsPage_AboutUsPage__3wk2o .AboutUsPage_title__tSKDA:hover {
  color: rgb(39, 194, 214);
}

.AboutUsPage_AboutUsPage__3wk2o .AboutUsPage_bioSection__12tRY {
  margin: 1rem;
}

.AboutUsPage_AboutUsPage__3wk2o .AboutUsPage_bio__3YiMe {
  margin: auto;
  max-width: 800px;
}

.Chip_RoundPaper__3W3ON {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-top: 0.3rem;
}

.Chip_RoundPaper__3W3ON .Chip_outer__1Bkdn,
.Chip_outerActive__1rse9,
.Chip_inner__20QIB,
.Chip_innerActive__2Wdjp,
.Chip_wrapper__20dSu,
.Chip_wrapperActive__1TOba {
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
}

.Chip_RoundPaper__3W3ON .Chip_outer__1Bkdn,
.Chip_outerActive__1rse9 {
  margin-bottom: 0.2rem;
  padding: 0.8rem;
  background: rgb(119, 188, 197);
  height: 132px;
  width: 132px;
}

.Chip_RoundPaper__3W3ON .Chip_outer__1Bkdn:hover,
.Chip_outerActive__1rse9 {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16), 0 5px 15px rgba(0, 0, 0, 0.23);
}

.Chip_RoundPaper__3W3ON .Chip_inner__20QIB,
.Chip_innerActive__2Wdjp {
  padding: 0.6rem;
  background: rgb(202, 237, 243);
  height: 112px;
  width: 112px;
}

.Chip_RoundPaper__3W3ON .Chip_inner__20QIB:hover,
.Chip_innerActive__2Wdjp {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 4px 12px rgba(0, 0, 0, 0.23);
}

.Chip_RoundPaper__3W3ON .Chip_wrapper__20dSu,
.Chip_wrapperActive__1TOba {
  height: 110px;
  width: 110px;
}

.Chip_RoundPaper__3W3ON .Chip_wrapper__20dSu:hover,
.Chip_wrapperActive__1TOba {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Chip_RoundPaper__3W3ON .Chip_name__2bYHU,
.Chip_nameActive__3Eo_l {
  font-size: 14px;
  line-height: 25px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Chip_RoundPaper__3W3ON .Chip_name__2bYHU:hover,
.Chip_nameActive__3Eo_l {
  color: rgb(202, 237, 243);
}

.SlantedSection_SlantedSection__2Thc6 {
  position: relative;
  background: #92d3db;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.SlantedSection_SlantedSection__2Thc6:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.SlantedSection_SlantedSection__2Thc6 .SlantedSection_shadowDark__2MfSZ {
  position: absolute;
  top: 0;
  left: 0;
  background: #027888;
  width: 100%;
  height: 95%;
  z-index: -10;
  -webkit-clip-path: polygon(0 180px, 100% 100px, 100% 90%, 0 95%);
          clip-path: polygon(0 180px, 100% 100px, 100% 90%, 0 95%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.SlantedSection_SlantedSection__2Thc6 .SlantedSection_shadowLight__3AHgP {
  position: absolute;
  top: 0;
  left: 0;
  background: #03a0b5;
  width: 100%;
  height: 100%;
  z-index: -100;
  -webkit-clip-path: polygon(0 0, 100% 120px, 100% 80%, 0 100%);
          clip-path: polygon(0 0, 100% 120px, 100% 80%, 0 100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.SlantedSection_SlantedSection__2Thc6 .SlantedSection_shadowLight__3AHgP:hover,
.SlantedSection_shadowDark__2MfSZ:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.ais-GeoSearch-map {
  position: relative;
  overflow: hidden;
  height: 500px;
  width: 100%;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  font-style: normal;
  font-weight: bold;
  color: #03a0b5;
}

.ais-SearchBox {
  margin-top: 1rem;
  position: relative;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  position: absolute;
  left: inherit;
  margin: 14px 16px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.ais-SearchBox-submit svg {
  width: 20px;
  height: 20px;
  display: block;
}

.ais-SearchBox-reset svg {
  width: 15px;
  height: 15px;
  display: block;
}

.ais-SearchBox-submit svg {
  fill: #03a0b5;
}

.ais-SearchBox-reset svg {
  fill: #ed5a6a;
}

.ais-SearchBox-submit {
  right: 0;
}

.ais-SearchBox-reset {
  right: 30px;
  top: 4px;
}

.ais-SearchBox-input {
  width: 100%;
  padding: 1rem 1.5rem;
  height: 50px;
  margin-bottom: 1rem;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  font-weight: normal;
  border: none;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ais-SearchBox-input:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}

.ais-MapControl {
  margin: 1rem 0;
}

#pagination {
  order: 1;
}

.ais-Pagination-list {
  display: flex;
  margin: 0 0 16px;
  padding: 0;
  background: none;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  box-shadow: none;
  flex-wrap: wrap;
}

.ais-Pagination-link {
  display: block;
  font-weight: bold;
}

.ais-Pagination-item,
.ais-Pagination-item--disabled {
  flex: 0 0 40px;
  width: auto;
  height: auto;
  margin: 4px;
  background: #03a0b5;
  border: 2px solid #03a0b5;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  text-align: center;
  border-radius: 4px;
  transition: all 0.1s linear;
}

.ais-Pagination-item--disabled {
  display: none;
}

.ais-Pagination-item:hover,
.ais-Pagination-item--selected {
  border: 2px solid rgb(39, 194, 214);
  background: rgb(39, 194, 214);
}

.ais-Pagination-item .ais-Pagination-link,
.ais-Pagination-item--disabled .ais-Pagination-link {
  padding: 6px 16px;
  text-decoration: none;
  color: #fff;
  line-height: 20px;
}

.SearchPage_SearchPage__3PRmo {
  margin: auto;
  padding: 1rem;
  max-width: 1000px;
}

.SearchPage_SearchPage__3PRmo .SearchPage_searchBox__3IWUM {
  margin-bottom: 1rem;
}

.Content_Hit__2KJhG {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Content_Hit__2KJhG:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Content_Hit__2KJhG .Content_avatar__3GPqo {
  border-radius: 10px;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding: 0.5rem;
  min-width: 120px;
  max-width: 150px;
}

.Content_Hit__2KJhG .Content_content__2lCbp {
  padding: 0.8rem;
}

.Content_Hit__2KJhG .Content_name__32hoD {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
}

.Content_Hit__2KJhG .Content_languages__1mDQz {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  color: #027888;
}

.Content_Hit__2KJhG .Content_location__1jp9A,
.Content_email__1xUD3 {
  font-size: 15px;
  line-height: 22px;
}

.InterpreterInfoModal_Modal__3ROtF {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_infoCard__3SW0F {
  padding: 1rem;
  width: 700px;
  margin: 1rem;
  background: #ffffff;
  border-radius: 10px;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_noReviews__3F7x9 {
  font-size: 16px;
  color: black;
  margin: 1rem 2rem;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_rating__2KOfu {
  margin-bottom: 0.5rem;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_language__3QTqT {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_infoTitle__3RiSg {
  color: #027888;
  font-weight: 600;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_name__wQEll {
  font-weight: bold;
  font-size: 28px;
  line-height: 25px;
  margin-top: -0.2rem;
  margin-bottom: 0.5rem;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_prompt__1tgv1 {
	font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-top: 0.1rem;
	margin-bottom: 0.1rem;
	text-align: center;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_verifyIcon__2WYta {
  margin-left: 0.5rem;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_title__2g8iO {
  font-weight: 500;
  font-size: 16px;
  color: #027888;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_infoItem__3zYI- {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #5a4f5a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_mtlInfoItem__1X-de {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #5a4f5a;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_mtlContent__14uoX {
  margin-left: 1.5rem;
  margin-top: -0.2rem;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_scale__3f7az {
  color: orangered;
  font-size: 14px;
  float: right;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_horzLine__lqYfQ {
  margin: 1rem 0;
  border: 1px solid #03a0b5;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_buttons__33ODB {
  margin: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_halfArea__2IkmI {
  max-height: 200px;
  overflow-y: scroll;
}

.InterpreterInfoModal_Modal__3ROtF .InterpreterInfoModal_infoSection__2N_3t {
  max-height: 300px;
  overflow-y: scroll;
}

.InterpreterInfoModal_moreInfo__3AM8q {
  font-size: 14px;
  color: #027888;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.InterpreterInfoModal_moreInfo__3AM8q:hover {
  color: rgb(39, 194, 214);
}

.CertificateItem_CertificateItem__jLooV {
  margin: 0.5rem 0.5rem;
}

.CertificateItem_CertificateItem__jLooV .CertificateItem_header__33yax {
  display: flex;
  justify-content: space-between;
}

.CertificateItem_CertificateItem__jLooV .CertificateItem_title__1F4cX {
  color: #5a4f5a;
  font-size: 20px;
  font-weight: 500;
}

.CertificateModal_CertificateModal__2x7Eu .CertificateModal_viewDocument__2iOS- {
  font-size: 13px;
  color: black;
  color: #03a0b5;
  margin-top: -0.2rem;
  transition: color 0.2s ease-in-out;
}

.CertificateModal_CertificateModal__2x7Eu .CertificateModal_viewDocument__2iOS-:hover {
  color: rgb(39, 194, 214);
}

.CertificateModal_CertificateModal__2x7Eu .CertificateModal_modal__3nhbE {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReviewItem_ReviewItem__3jXw6 {
  margin-left: 0.8rem;
  margin-bottom: 1rem;
  margin-top: 0.3rem;
  color: #5a4f5a;
}

.ReviewItem_ReviewItem__3jXw6 .ReviewItem_header__3hmGr {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
}

.ReviewItem_ReviewItem__3jXw6 .ReviewItem_comment__15lE8 {
  margin-left: 0.5rem;
  margin-top: -0.2rem;
  margin-bottom: -0.2rem;
  font-size: 14px;
}

.ReviewItem_ReviewItem__3jXw6 .ReviewItem_userName__E-vxq {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
}

.ReviewModal_Modal__2qy_Y {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReviewModal_Modal__2qy_Y .ReviewModal_reviewEditor__3Fx58 {
  padding: 1rem;
  width: 400px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ReviewModal_Modal__2qy_Y .ReviewModal_reviewEditor__3Fx58:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ReviewModal_Modal__2qy_Y .ReviewModal_title__xe9dH {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

.ReviewModal_Modal__2qy_Y .ReviewModal_comment__3bGKM {
  display: block;
  height: 200px;
  width: 100%;
  background: #ffffff;
  border: 2px solid #03a0b5;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 1rem;
  font-size: 16px;
  margin: 1rem 0;
}

.ReviewModal_Modal__2qy_Y .ReviewModal_buttons__1LORn {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem;
}

.LoadCircle_Container__2_w6g {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 10;
    position: fixed;
    align-items: center;
    touch-action: none;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    -webkit-tap-highlight-color: transparent;
}

.AdminPage_AdminPage__3aHGq {
  margin: auto;
  max-width: 1000px;
}

.AdminPage_AdminPage__3aHGq .AdminPage_menuWrapper__2lRc2 {
  margin: 2rem 1rem 0 1rem;
}

.AdminPage_AdminPage__3aHGq .AdminPage_window__1hwtb {
  margin: 0rem 1rem 0 1rem;
}

.AdminPage_AdminPage__3aHGq .AdminPage_title__3J32S {
  font-size: 20px;
  color: #03a0b5;
}

.AdminPage_AdminPage__3aHGq .AdminPage_noItems__2DiYm {
  margin: 1rem;
  text-align: center;
}

.AdminPage_AdminPage__3aHGq .AdminPage_buttons__1w6N_ {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.AdminPage_AdminPage__3aHGq .AdminPage_window__1hwtb {
  margin-top: 2rem;
}

.AdminPage_AdminPage__3aHGq .AdminPage_adminCodeButton__F4mH- {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}

.AdminPage_AdminPage__3aHGq .AdminPage_menu__PMd17 {
  padding: 1rem 1.5rem 0 1.5rem;
}

.AdminPage_AdminPage__3aHGq .AdminPage_menuItemWrapper__2tSH3 {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.AdminPage_AdminPage__3aHGq .AdminPage_menuItemWrapper__2tSH3:hover {
  transform: translateX(10px);
}

.AdminPage_AdminPage__3aHGq .AdminPage_menuItem__3XHz6 {
  color: black;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.AdminPage_AdminPage__3aHGq .AdminPage_menuItem__3XHz6:hover {
  color: #03a0b5;
}

.AdminPage_AdminPage__3aHGq .AdminPage_activeMenuItem__ZJvsR {
  color: #03a0b5;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.AdminPage_AdminPage__3aHGq .AdminPage_dot__1JU-p {
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
}

.AdminPage_AdminPage__3aHGq .AdminPage_activeDot__wzwZP {
  height: 8px;
  width: 8px;
  background-color: #03a0b5;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
  transition: background-color 0.2s ease-in-out;
}

.EventCard_EventCard__3PxTr .EventCard_past__3K78I {
  opacity: 0.8;
}

.EventCard_EventCard__3PxTr .EventCard_header__3utHz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EventCard_EventCard__3PxTr .EventCard_eventTitle__3xjIf {
  font-weight: 600;
  font-size: 25px;
}

.EventCard_EventCard__3PxTr .EventCard_dateLocation__20LJ- {
  text-align: right;
}

.EventCard_EventCard__3PxTr .EventCard_date__W-Ud_ {
  font-size: 15px;
  font-weight: 500;
}

.EventCard_EventCard__3PxTr .EventCard_location__2JF98 {
  font-size: 15px;
}

.EventCard_EventCard__3PxTr .EventCard_footer__3_6wZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.EventCard_EventCard__3PxTr .EventCard_note__MFGEl {
  font-size: 12px;
  font-weight: 500;
  color: green;
}

.EventCard_EventCard__3PxTr .EventCard_buttons__1jyoI {
  display: flex;
  align-items: center;
}

.DoubleCheckModal_Modal__1_W-z {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DoubleCheckModal_Modal__1_W-z .DoubleCheckModal_Box__1f8se {
  padding: 1rem;
  width: 400px;
  background: #ffffff;
  border-radius: 10px;
}

.DoubleCheckModal_Modal__1_W-z .DoubleCheckModal_Box__1f8se .DoubleCheckModal_question__1P8jA {
  font-size: 20px;
  font-weight: 700;
  margin: 1rem 0;
}

.DoubleCheckModal_Modal__1_W-z .DoubleCheckModal_Box__1f8se .DoubleCheckModal_choices__3z05k {
  display: flex;
  justify-content: flex-end;
}

.EventModal_Modal__6yEvO {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EventModal_Modal__6yEvO .EventModal_reviewEditor__38q1z {
  padding: 1rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.EventModal_Modal__6yEvO .EventModal_title__3p_tR {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

.EventModal_Modal__6yEvO .EventModal_comment__2OjZo {
  display: block;
  height: 200px;
  width: 100%;
  background: #ffffff;
  border: 2px solid #03a0b5;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 1rem;
  font-size: 16px;
  margin: 1rem 0;
}

.EventModal_Modal__6yEvO .EventModal_buttons__kueb7 {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem;
}

.EventModal_Modal__6yEvO .EventModal_targetArea__2lmpT {
  align-items: center;
  display: flex;
}

.EventModal_Modal__6yEvO .EventModal_label__2wipe {
  margin: 5px 0 -8px 0;
}

.HorzLine_HorzLine__Cjxvc {
  margin: 2rem 15%;
  border: 2px solid #03a0b5;
  border-radius: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.12);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.HorzLine_HorzLine__Cjxvc:hover {
  border-color: rgb(39, 194, 214);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.16);
}

.UserTag_UserTag__1yuE1 {
  display: flex;
  align-items: center;
  color: white;
}

.UserTag_UserTag__1yuE1 .UserTag_name__2B4Ir {
  margin-left: 0.5rem;
  font-size: 22px;
  font-weight: 600;
}

.UserTag_UserTag__1yuE1 .UserTag_flexArea__1hb8J {
  display: flex;
  align-items: center;
}

.UserTag_UserTag__1yuE1 .UserTag_icon__2jD-d {
  margin-left: 0.5rem;
}

.UserTag_UserTag__1yuE1 .UserTag_rating___W9p- {
  margin-left: 0.4rem;
}

.InterpreterReviewCard_InterpreterReviewCard___qxna .InterpreterReviewCard_header__2-070 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InterpreterReviewCard_InterpreterReviewCard___qxna .InterpreterReviewCard_icon__2CgXr {
  margin-left: 0.5rem;
}

.InterpreterReviewCard_InterpreterReviewCard___qxna .InterpreterReviewCard_infoItem__KEPPC {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 18px;
}

.InterpreterReviewCard_InterpreterReviewCard___qxna .InterpreterReviewCard_location__2JWpW {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}

.InterpreterReviewCard_InterpreterReviewCard___qxna .InterpreterReviewCard_flexArea__3IiDI {
  display: flex;
  align-items: center;
}

.InterpreterReviewCard_InterpreterReviewCard___qxna .InterpreterReviewCard_bottom__Du9A2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

.InterpreterReviewCard_InterpreterReviewCard___qxna .InterpreterReviewCard_deleteButton__1pXNq {
  margin-left: 0.5rem;
}

.CertificationCard_CertificationCard__1imAb {
  margin: 1rem 0;
  padding: 0.8rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.CertificationCard_CertificationCard__1imAb:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_header__186EN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_footer__2duxT {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.8rem;
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_userInfo__1o7nz {
  display: flex;
  align-items: center;
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_infoItem__3qrNb {
  display: block;
  margin-left: 10px;
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_expand__1xF9w {
  -webkit-animation: CertificationCard_appear__w_HQ1 0.3s;
          animation: CertificationCard_appear__w_HQ1 0.3s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes CertificationCard_appear__w_HQ1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes CertificationCard_appear__w_HQ1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_collapse__2yoV6 {
  display: none;
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_checkIcon__3tgQR {
  margin-left: 0.5rem;
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_title__1RENa {
  display: flex;
  align-items: center;
}

.CertificationCard_CertificationCard__1imAb .CertificationCard_buttons__2HaJw {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.AdminSignUpPage_AdminSignUpPage__2MfGN {
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  margin: auto;
  max-width: 800px;
}

.AdminSignUpPage_AdminSignUpPage__2MfGN .AdminSignUpPage_title__2EmDM {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
}

.AdminSignUpPage_AdminSignUpPage__2MfGN .AdminSignUpPage_header__3-Vg4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminSignUpPage_AdminSignUpPage__2MfGN .AdminSignUpPage_footer__3jgyt {
  display: flex;
  flex-direction: column;
}

.AdminSignUpPage_AdminSignUpPage__2MfGN .AdminSignUpPage_buttons__e_TaP {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem;
}

.InterpreterPage_InterpreterPage__3HRE- {
  margin: auto;
  max-width: 1000px;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_menuWrapper__3TJTL {
  margin: 2rem 1rem 0 1rem;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_window__3uaGa {
  margin: 0rem 1rem 0 1rem;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_noEvents__3E5FR {
  margin: 1rem;
  text-align: center;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_buttons__2kbNJ {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_currentPassword__3eIb6 {
  margin: 0 10px;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_label__2uEAT {
  font-weight: 400;
  font-size: 19px;
  margin: -3px 20px 0 0;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_menu__249zL {
  padding: 1rem 1.5rem 0 1.5rem;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_menuItemWrapper__1VCYo {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_menuItemWrapper__1VCYo:hover {
  transform: translateX(10px);
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_menuItem__eAj62 {
  color: black;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_menuItem__eAj62:hover {
  color: #03a0b5;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_activeMenuItem__1TShV {
  color: #03a0b5;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_window__3uaGa {
  margin-top: 2rem;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_dot__3-tIr {
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_activeDot__DgVXc {
  height: 8px;
  width: 8px;
  background-color: #03a0b5;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
  transition: background-color 0.2s ease-in-out;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_langFieldIcons__L7ZvM {
  margin-top: 0.9rem;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_langFieldIcon__1gR_G:hover {
  -webkit-filter: brightness(150%);
          filter: brightness(150%);
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_reviewWindow__F1L-6 {
  max-height: 600px;
  overflow-y: scroll;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_reviewItem__1nkuw {
  margin: 1rem 1rem;
}

.InterpreterPage_InterpreterPage__3HRE- .InterpreterPage_noItems__1Ky_r {
  margin: 1rem;
  text-align: center;
}

.ClientPage_ClientPage__3tLS1 {
  margin: auto;
  max-width: 1000px;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_menuWrapper__9UEDE {
  margin: 2rem 1rem 0 1rem;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_window__my5nf {
  margin: 0rem 1rem 0 1rem;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_noEvents__1hd0I {
  margin: 1rem;
  text-align: center;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_buttons__3-U5E {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_currentPassword__37_8D {
  margin: 0 10px;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_label__30ftU {
  font-weight: 400;
  font-size: 19px;
  margin: -3px 20px 0 0;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_menu__8RPJH {
  padding: 1rem 1.5rem 0 1.5rem;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_menuItemWrapper__8WfFg {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_menuItem__3NUzc {
  color: black;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_menuItem__3NUzc:hover {
  color: #03a0b5;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_activeMenuItem__1Wpsf {
  color: #03a0b5;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_menuItemWrapper__8WfFg {
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_menuItemWrapper__8WfFg:hover {
  transform: translateX(10px);
}

.ClientPage_ClientPage__3tLS1 .ClientPage_window__my5nf {
  margin-top: 2rem;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_dot__3-Jrt {
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_activeDot__2UCLo {
  height: 8px;
  width: 8px;
  background-color: #03a0b5;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
  transition: background-color 0.2s ease-in-out;
}

.ClientPage_ClientPage__3tLS1 .ClientPage_noItems__3dPY5 {
  margin: 1rem;
  text-align: center;
}

.Bookmark_Bookmark__3ZJVM {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Bookmark_Bookmark__3ZJVM:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Bookmark_Bookmark__3ZJVM .Bookmark_avatar__YX_6m {
  border-radius: 10px;
  margin-left: 0.5rem;
  padding: 0.5rem;
  min-width: 120px;
  max-width: 150px;
}

.Bookmark_Bookmark__3ZJVM .Bookmark_content__36ylV {
  padding: 0.2rem 0.8rem 0.8rem 0;
}

.Bookmark_Bookmark__3ZJVM .Bookmark_name__3nFbT {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.Bookmark_Bookmark__3ZJVM .Bookmark_bookmark__2KU3O {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.Bookmark_Bookmark__3ZJVM .Bookmark_languages__Slr81 {
  font-size: 18px;
  line-height: 20px;
  color: #027888;
}

.Bookmark_Bookmark__3ZJVM .Bookmark_location__2Hc3l,
.Bookmark_email__2a7jV,
.Bookmark_phone__2JwHv {
  line-height: 22px;
}

.ResetPasswordPage_ResetPasswordPage__21y4L {
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: auto;
    max-width: 800px;
}

.ResetPasswordPage_ResetPasswordPage__21y4L .ResetPasswordPage_title__2MgYm {
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
}

.ResetPasswordPage_ResetPasswordPage__21y4L .ResetPasswordPage_header__2Vj-Z {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ResetPasswordPage_ResetPasswordPage__21y4L .ResetPasswordPage_footer__2IEOI {
    display: flex;
    flex-direction: column;
}

.ResetPasswordPage_ResetPasswordPage__21y4L .ResetPasswordPage_buttons__1Djv- {
    display: flex;
    justify-content: flex-end;
    margin: 1rem .5rem;
}

