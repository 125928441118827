.HomePage {
  padding: 10px 5%;
}

.HomePage .section {
  margin: 1rem auto;
  max-width: 1000px;
}

.HomePage .infoSection {
  margin: 1.5rem auto;
  max-width: 1200px;
}

.HomePage .previewSection {
  margin: 1.5rem auto;
  max-width: 1000px;
}

.HomePage .invertedSection {
  margin: 0 -6%;
  padding: 5% 5%;
  background: #03a0b5;
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.HomePage .invertedSection:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.HomePage .previewHeader {
  text-align: center;
}

.HomePage .title {
  font-size: 28px;
  line-height: 30px;
  color: #027888;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-align: center;
}

.HomePage .title:hover {
  color: rgb(39, 194, 214);
}

.HomePage .image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.HomePage .quote {
  font-size: 30px;
}

.HomePage .centeredQuote {
  font-size: 25px;
  text-align: center;
}

.HomePage .infoItem {
  display: block;
  margin-left: 10px;
}

.HomePage .previewArea {
  padding: 2rem 0;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.HomePage .previewArea::-webkit-scrollbar {
  display: none;
}

.HomePage .previewCard {
  scroll-snap-align: center;
}

.HomePage .joinSection {
  margin: 0 -6%;
  padding: 2rem 10%;
  background: rgb(235, 247, 249);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.HomePage .joinSection:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
}

.HomePage .icon {
  margin: 1rem 0.5rem 0;
}

.HomePage .paragraph {
  text-align: justify;
}
