.RoundPaper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-top: 0.3rem;
}

.RoundPaper .outer,
.outerActive,
.inner,
.innerActive,
.wrapper,
.wrapperActive {
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
}

.RoundPaper .outer,
.outerActive {
  margin-bottom: 0.2rem;
  padding: 0.8rem;
  background: rgb(119, 188, 197);
  height: 132px;
  width: 132px;
}

.RoundPaper .outer:hover,
.outerActive {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16), 0 5px 15px rgba(0, 0, 0, 0.23);
}

.RoundPaper .inner,
.innerActive {
  padding: 0.6rem;
  background: rgb(202, 237, 243);
  height: 112px;
  width: 112px;
}

.RoundPaper .inner:hover,
.innerActive {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 4px 12px rgba(0, 0, 0, 0.23);
}

.RoundPaper .wrapper,
.wrapperActive {
  height: 110px;
  width: 110px;
}

.RoundPaper .wrapper:hover,
.wrapperActive {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.RoundPaper .name,
.nameActive {
  font-size: 14px;
  line-height: 25px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.RoundPaper .name:hover,
.nameActive {
  color: rgb(202, 237, 243);
}
