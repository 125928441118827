.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal .Box {
  padding: 1rem;
  width: 400px;
  background: #ffffff;
  border-radius: 10px;
}

.Modal .Box .question {
  font-size: 20px;
  font-weight: 700;
  margin: 1rem 0;
}

.Modal .Box .choices {
  display: flex;
  justify-content: flex-end;
}
