.AdminSignUpPage {
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  margin: auto;
  max-width: 800px;
}

.AdminSignUpPage .title {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
}

.AdminSignUpPage .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminSignUpPage .footer {
  display: flex;
  flex-direction: column;
}

.AdminSignUpPage .buttons {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem;
}
