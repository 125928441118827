.ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.ais-GeoSearch-map {
  position: relative;
  overflow: hidden;
  height: 500px;
  width: 100%;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  font-style: normal;
  font-weight: bold;
  color: #03a0b5;
}

.ais-SearchBox {
  margin-top: 1rem;
  position: relative;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  position: absolute;
  left: inherit;
  margin: 14px 16px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.ais-SearchBox-submit svg {
  width: 20px;
  height: 20px;
  display: block;
}

.ais-SearchBox-reset svg {
  width: 15px;
  height: 15px;
  display: block;
}

.ais-SearchBox-submit svg {
  fill: #03a0b5;
}

.ais-SearchBox-reset svg {
  fill: #ed5a6a;
}

.ais-SearchBox-submit {
  right: 0;
}

.ais-SearchBox-reset {
  right: 30px;
  top: 4px;
}

.ais-SearchBox-input {
  width: 100%;
  padding: 1rem 1.5rem;
  height: 50px;
  margin-bottom: 1rem;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  font-weight: normal;
  border: none;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ais-SearchBox-input:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}

.ais-MapControl {
  margin: 1rem 0;
}

#pagination {
  order: 1;
}

.ais-Pagination-list {
  display: flex;
  margin: 0 0 16px;
  padding: 0;
  background: none;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  box-shadow: none;
  flex-wrap: wrap;
}

.ais-Pagination-link {
  display: block;
  font-weight: bold;
}

.ais-Pagination-item,
.ais-Pagination-item--disabled {
  flex: 0 0 40px;
  width: auto;
  height: auto;
  margin: 4px;
  background: #03a0b5;
  border: 2px solid #03a0b5;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  text-align: center;
  border-radius: 4px;
  transition: all 0.1s linear;
}

.ais-Pagination-item--disabled {
  display: none;
}

.ais-Pagination-item:hover,
.ais-Pagination-item--selected {
  border: 2px solid rgb(39, 194, 214);
  background: rgb(39, 194, 214);
}

.ais-Pagination-item .ais-Pagination-link,
.ais-Pagination-item--disabled .ais-Pagination-link {
  padding: 6px 16px;
  text-decoration: none;
  color: #fff;
  line-height: 20px;
}
