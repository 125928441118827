.InterpreterReviewCard .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InterpreterReviewCard .icon {
  margin-left: 0.5rem;
}

.InterpreterReviewCard .infoItem {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 18px;
}

.InterpreterReviewCard .location {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}

.InterpreterReviewCard .flexArea {
  display: flex;
  align-items: center;
}

.InterpreterReviewCard .bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

.InterpreterReviewCard .deleteButton {
  margin-left: 0.5rem;
}
