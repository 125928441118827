.Bookmark {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Bookmark:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.Bookmark .avatar {
  border-radius: 10px;
  margin-left: 0.5rem;
  padding: 0.5rem;
  min-width: 120px;
  max-width: 150px;
}

.Bookmark .content {
  padding: 0.2rem 0.8rem 0.8rem 0;
}

.Bookmark .name {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  display: flex;
  align-items: center;
}

.Bookmark .bookmark {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.Bookmark .languages {
  font-size: 18px;
  line-height: 20px;
  color: #027888;
}

.Bookmark .location,
.email,
.phone {
  line-height: 22px;
}
