.NavBar {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.NavBar .logo {
  margin-top: 0.3rem;
  margin-left: 0.8rem;
  font-size: 20px;
  font-weight: 500;
  align-self: center;
  text-decoration: none;
  color: black;
}

.NavBar .items {
  display: flex;
}

.NavBar .item {
  margin-right: 1rem;
  font-size: 18px;
  align-self: center;
  text-decoration: none;
  color: black;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.NavBar .button {
  margin-right: 1rem;
  align-self: center;
}

.NavBar .item:hover {
  color: #03a0b5;
}

.NavBar .activeItem {
  margin-right: 1rem;
  font-size: 18px;
  align-self: center;
  text-decoration: none;
  color: #03a0b5;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
