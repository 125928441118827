.ResetPasswordPage {
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: auto;
    max-width: 800px;
}

.ResetPasswordPage .title {
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
}

.ResetPasswordPage .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ResetPasswordPage .footer {
    display: flex;
    flex-direction: column;
}

.ResetPasswordPage .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 1rem .5rem;
}
