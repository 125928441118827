.AboutUsPage {
  padding-top: 2rem;
  position: relative;
}

.AboutUsPage .avatarSection {
  margin: auto;
  max-width: 800px;
  padding-top: 1rem;
  margin-top: 0.5rem;
}

.AboutUsPage .title {
  font-size: 30px;
  line-height: 30px;
  color: #027888;
  text-align: center;
  margin-bottom: 1.5rem;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.AboutUsPage .title:hover {
  color: rgb(39, 194, 214);
}

.AboutUsPage .bioSection {
  margin: 1rem;
}

.AboutUsPage .bio {
  margin: auto;
  max-width: 800px;
}
