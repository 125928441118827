.CertificateItem {
  margin: 0.5rem 0.5rem;
}

.CertificateItem .header {
  display: flex;
  justify-content: space-between;
}

.CertificateItem .title {
  color: #5a4f5a;
  font-size: 20px;
  font-weight: 500;
}
