.EventCard .past {
  opacity: 0.8;
}

.EventCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EventCard .eventTitle {
  font-weight: 600;
  font-size: 25px;
}

.EventCard .dateLocation {
  text-align: right;
}

.EventCard .date {
  font-size: 15px;
  font-weight: 500;
}

.EventCard .location {
  font-size: 15px;
}

.EventCard .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.EventCard .note {
  font-size: 12px;
  font-weight: 500;
  color: green;
}

.EventCard .buttons {
  display: flex;
  align-items: center;
}
