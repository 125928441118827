.ClientPage {
  margin: auto;
  max-width: 1000px;
}

.ClientPage .menuWrapper {
  margin: 2rem 1rem 0 1rem;
}

.ClientPage .window {
  margin: 0rem 1rem 0 1rem;
}

.ClientPage .noEvents {
  margin: 1rem;
  text-align: center;
}

.ClientPage .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.ClientPage .currentPassword {
  margin: 0 10px;
}

.ClientPage .label {
  font-weight: 400;
  font-size: 19px;
  margin: -3px 20px 0 0;
}

.ClientPage .menu {
  padding: 1rem 1.5rem 0 1.5rem;
}

.ClientPage .menuItemWrapper {
  display: flex;
  align-items: center;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.ClientPage .menuItem {
  color: black;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.ClientPage .menuItem:hover {
  color: #03a0b5;
}

.ClientPage .activeMenuItem {
  color: #03a0b5;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.ClientPage .menuItemWrapper {
  display: flex;
  align-items: center;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

.ClientPage .menuItemWrapper:hover {
  transform: translateX(10px);
}

.ClientPage .window {
  margin-top: 2rem;
}

.ClientPage .dot {
  height: 8px;
  width: 8px;
  background-color: black;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
}

.ClientPage .activeDot {
  height: 8px;
  width: 8px;
  background-color: #03a0b5;
  border-radius: 50%;
  margin: 0 1rem 0.5rem 0;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.ClientPage .noItems {
  margin: 1rem;
  text-align: center;
}
