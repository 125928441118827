.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal .reviewEditor {
  padding: 1rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Modal .title {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

.Modal .comment {
  display: block;
  height: 200px;
  width: 100%;
  background: #ffffff;
  border: 2px solid #03a0b5;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 1rem;
  font-size: 16px;
  margin: 1rem 0;
}

.Modal .buttons {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem;
}

.Modal .targetArea {
  align-items: center;
  display: flex;
}

.Modal .label {
  margin: 5px 0 -8px 0;
}
