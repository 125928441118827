.Footer {
  font-weight: 500;
  text-align: center;
  color: #027888;
  margin-bottom: 1.5rem;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.Footer:hover {
  color: rgb(39, 194, 214);
}
