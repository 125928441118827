.Paper,
.invertedPaper,
.lightPaper {
  margin: 1rem 0;
  padding: 1rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.invertedPaper {
  background: #03a0b5;
}

.invertedPaper:hover {
  background: rgb(39, 194, 214);
}

.Paper:hover,
.invertedPaper:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.lightPaper:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16), 0 5px 15px rgba(0, 0, 0, 0.23);
}
