.PreviewCard {
  margin: 0 3rem;
}

.PreviewCard .inner {
  display: flex;
  align-items: center;
  background: #ffffff;
  min-width: 400px;
  margin: -0.5rem;
}

.PreviewCard .avatar {
  margin: 1rem 1.5rem 1rem 1rem;
}

.PreviewCard .name {
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
}

.PreviewCard .languages {
  font-size: 18px;
  line-height: 20px;
  color: #027888;
  margin-bottom: 0.5rem;
}

.PreviewCard .location,
.email {
  line-height: 20px;
}
