.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal .infoCard {
  padding: 1rem;
  width: 700px;
  margin: 1rem;
  background: #ffffff;
  border-radius: 10px;
}

.Modal .noReviews {
  font-size: 16px;
  color: black;
  margin: 1rem 2rem;
}

.Modal .rating {
  margin-bottom: 0.5rem;
}

.Modal .language {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.Modal .infoTitle {
  color: #027888;
  font-weight: 600;
}

.Modal .name {
  font-weight: bold;
  font-size: 28px;
  line-height: 25px;
  margin-top: -0.2rem;
  margin-bottom: 0.5rem;
}

.Modal .prompt {
	font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-top: 0.1rem;
	margin-bottom: 0.1rem;
	text-align: center;
}

.Modal .verifyIcon {
  margin-left: 0.5rem;
}

.Modal .title {
  font-weight: 500;
  font-size: 16px;
  color: #027888;
}

.Modal .infoItem {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #5a4f5a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Modal .mtlInfoItem {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #5a4f5a;
}

.Modal .mtlContent {
  margin-left: 1.5rem;
  margin-top: -0.2rem;
}

.Modal .scale {
  color: orangered;
  font-size: 14px;
  float: right;
}

.Modal .horzLine {
  margin: 1rem 0;
  border: 1px solid #03a0b5;
}

.Modal .buttons {
  margin: 0.5rem;
  display: flex;
  justify-content: flex-end;
}

.Modal .halfArea {
  max-height: 200px;
  overflow-y: scroll;
}

.Modal .infoSection {
  max-height: 300px;
  overflow-y: scroll;
}

.moreInfo {
  font-size: 14px;
  color: #027888;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.moreInfo:hover {
  color: rgb(39, 194, 214);
}
