.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal .card {
  padding: 1rem;
  width: 90%;
  max-width: 800px;
  background: #ffffff;
  border-radius: 10px;
}

.Modal .title {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
}

.Modal .footer {
  display: flex;
  flex-direction: column;
}

.Modal .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.Modal .kindArea {
  align-items: center;
  display: flex;
}

.Modal .label {
  font-weight: 400;
  font-size: 18px;
  margin: -3px 20px 0 0;
}

.Modal .serviceLabel {
  font-weight: 400;
  font-size: 18px;
  margin: 10px 1.5rem 0 0;
}

.Modal .serviceArea {
  display: flex;
}

.Modal .langFieldIcons {
  margin-top: 0.9rem;
}

.Modal .langFieldIcon:hover {
  filter: brightness(150%);
}

.Modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
