.HorzLine {
  margin: 2rem 15%;
  border: 2px solid #03a0b5;
  border-radius: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.12);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.HorzLine:hover {
  border-color: rgb(39, 194, 214);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.16);
}
