.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal .card {
  padding: 1rem;
  width: 400px;
  background: #ffffff;
  border-radius: 10px;
}

.Modal .title {
  font-weight: 500;
  font-size: 25px;
  line-height: 45px;
}

.Modal .footer {
  display: flex;
  flex-direction: column;
}

.Modal .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.Modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Modal .instruction {
  font-size: 14px;
}
