.CertificationCard {
  margin: 1rem 0;
  padding: 0.8rem;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.CertificationCard:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.CertificationCard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}

.CertificationCard .footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.8rem;
}

.CertificationCard .userInfo {
  display: flex;
  align-items: center;
}

.CertificationCard .infoItem {
  display: block;
  margin-left: 10px;
}

.CertificationCard .expand {
  animation: appear 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.CertificationCard .collapse {
  display: none;
}

.CertificationCard .checkIcon {
  margin-left: 0.5rem;
}

.CertificationCard .title {
  display: flex;
  align-items: center;
}

.CertificationCard .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
